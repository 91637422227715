<template>
  <section class="reset-password">
    <div class="grid-x">
      <div class="cell auto">
        <form @submit.prevent="lostPassword" v-if="newPasswordEmailSent">
          <h4 class="title">Mise à jour du mot passe</h4>
          <div class="row forgotten-password-section">
            <h4>Un lien vous a été envoyé à l'adresse {{ email }}</h4>
          </div>
          <div class="row">
            <router-link :to="{ name: 'login' }">retour</router-link>
          </div>
        </form>
        <form class="new-password-section" v-else @submit.prevent="changePassword">
          <h4 class="title">Réinitialisez votre mot de passe</h4>
          <div class="row">
            <app-input
              label="Nouveau mot de passe"
              v-model="newPassword"
              placeholder="Nouveau mot de passe..."
              type="password"
              :hasSucceed="isFormInvalid ? 'error' : null" :msg="isFormInvalid ? errorMessage : null"
              required :minlength="8" />
          </div>
          <div class="row">
            <app-input
              label="Confirmez le mot de passe"
              v-model="confirmNewPassword"
              placeholder="Confirmation du mot de passe..."
              type="password"
              :hasSucceed="isFormInvalid ? 'error' : null"
              :msg="isFormInvalid ? errorMessage : null"
              required
              :minlength="8" />
          </div>
          <div class="row">
            <app-button type="submit">Valider</app-button>
          </div>
          <div class="row">
            <router-link :to="{ name: 'login' }">Retour</router-link>
          </div>
        </form>
      </div>
      <div class="cell auto presentation">
        <div class="blur"></div>
        <div class="content">
          <logo-bird class="logo"/>
          <h6>Le plus court chemin entre vous et votre maison</h6>
          <router-link class="back-link" :to="{ name: 'home' }">
            <app-button theme="secondary">Retour au site</app-button>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import authApi from '@/services/auth';
import logoBird from '@/assets/logos/bird.svg?inline';

export default {
  components: {
    'logo-bird': logoBird,
  },
  data() {
    return {
      newPassword: '',
      confirmNewPassword: '',
      errorMessage: '',
      isFormInvalid: false,
      newPasswordEmailSent: null,
    };
  },
  watch: {
    newPassword() {
      if (this.newPassword !== this.confirmNewPassword && this.confirmNewPassword !== '' && this.newPassword !== '') {
        this.isFormInvalid = true;
        this.errorMessage = 'Les mots de passe ne correspondent pas';
      } else {
        this.isFormInvalid = false;
      }
    },
    confirmNewPassword() {
      if (this.newPassword !== this.confirmNewPassword && this.confirmNewPassword !== '' && this.newPassword !== '') {
        this.isFormInvalid = true;
        this.errorMessage = 'Les mots de passe ne correspondent pas';
      } else {
        this.isFormInvalid = false;
      }
    },
  },
  methods: {
    async changePassword() {
      this.errorMessage = '';
      if (this.newPassword !== this.confirmNewPassword || this.newPassword === '' || this.confirmNewPassword === '') {
        this.isFormInvalid = true;
        this.errorMessage = 'Les mots de passe ne correspondent pas';
      } else {
        try {
          await authApi.newPassword(this.newPassword, this.$route.query.token);
          this.$notification.show({ text: 'Mot de passe modifié' });

          this.$router.push({ name: 'login' });
        } catch (e) {
          this.isFormInvalid = true;
          this.errorMessage = 'Une erreur est survenue';
        }
      }
    },
  },
};

</script>

<style lang="sass" scoped>
  .reset-password
    margin-top: 70px
    > .grid-x
      min-height: 80vh
    .cell
      padding: 40px
    .title
      color: black
      font-size: 20px
      font-weight: 500
    .row
      @include row
      width: 100%
    form
      display: flex
      flex-direction: column
      justify-content: center
      height: 100%
      max-width: 400px
      margin: auto
      h4
        color: $body-color
      button
        width: fit-content
        padding-left: 20px
        padding-rigth: 20px
    .presentation
      background-image: url(../../assets/img/pages/background-house.jpeg)
      background-size: cover
      background-position: center
      position: relative
      @media (max-width: 1080px)
        display: none
      .blur
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        z-index: 1
        @include black-filter
      .content
        height: 100%
        z-index: 2
        position: relative
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .logo
          margin-bottom: 20px
      h6
        color: $white
      .back-link
        margin-top: 30px
        margin-bottom: 50px
        text-decoration: none
</style>
